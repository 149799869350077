import axiosInstance from './axiosInstance';
import {
    GET_TNL_LIST_SUCCESS,
    GET_TNL_SUCCESS,
    TNL_COPY_SUCCESS,
    TNL_PUBLISH_SUCCESS,
    TNL_RECOPY_SUCCESS,
    TNL_REPUBLISH_SUCCESS,
    REQUEST_RM_SUCCESS
} from './types';
import {catchFunction, getConfig} from "./utils";

export function tradeNameLibraryPublish(data) {
  return (dispatch) => {
    return axiosInstance
        .post(`/rest_api/formulator/compound_ingredient/${data.rmId}/trade_name_library_publish`, data.data, getConfig(),)
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: TNL_PUBLISH_SUCCESS,
            data: response,
          });

          return { type: TNL_PUBLISH_SUCCESS }
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: tradeNameLibraryPublish(data)
          })
        });
  };
}

export function tradeNameLibraryRepublish(data) {
  return (dispatch) => {
    return axiosInstance
        .patch(`/rest_api/formulator/compound_ingredient/${data.rmId}/trade_name_library_republish`, data.data, getConfig(),)
        .then((response) => { return response.data; })
        .then((response) => {
          dispatch({
            type: TNL_REPUBLISH_SUCCESS,
            data: response,
          });

            return { type: TNL_REPUBLISH_SUCCESS }
        })
        .catch((error) => {
          catchFunction({
            error,
            dispatch,
            refreshFunction: tradeNameLibraryRepublish(data)
          })
        });
  };
}

export function tradeNameLibraryUnpublish(data) {
    return (dispatch) => {
        return axiosInstance
            .delete(`/rest_api/formulator/compound_ingredient/trade_name_library_unpublish`, getConfig(data),)
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: TNL_PUBLISH_SUCCESS,
                    data: response,
                });
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: tradeNameLibraryUnpublish(data)
                })
            });
    };
}

export function getTNLList(data) {
    const searchQuery = data?.searchField ? `&search=${encodeURIComponent(data?.searchField)}` : '';
    const functionIds = data?.functionsForRequest?.length
        ? `&function_ids=${data?.functionsForRequest?.join('&function_ids=')}`
        : '';
    const manufacturerIds = data?.manufacturersForRequest?.length
        ? `&manufacturer_ids=${data?.manufacturersForRequest.join('&manufacturer_ids=')}`
        : '';
    const applicationIds = data?.applicationsForRequest?.length
        ? `&application_ids=${data?.applicationsForRequest.join('&application_ids=')}`
        : '';
    const complianceIds = data?.complianceForRequest?.length
        ? `&toxin_group_ids=${data?.complianceForRequest.join('&toxin_group_ids=')}`
        : '';
    const certificationIds = data?.certificationsForRequest?.length
        ? `&certification_ids=${data?.certificationsForRequest.join('&certification_ids=')}`
        : '';
    const blackListIds = data?.blackListForRequest?.length
    ? `&black_list_ids=${data?.blackListForRequest.join('&black_list_ids=')}`
    : '';
    const filters = `${functionIds}${certificationIds}${manufacturerIds}${applicationIds}${complianceIds}${blackListIds}`;

    const offset = data?.offset || 0;

    return (dispatch) => {
        return axiosInstance
            .get(`/rest_api/trade_name_library?offset=${offset}&limit=100${searchQuery}${filters}`, getConfig())
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch({
                    type: GET_TNL_LIST_SUCCESS,
                    data: response,
                });
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: getTNLList(data)
                })
            });
    };
}

export function getTNL(id) {
    return (dispatch) => {
        return axiosInstance
            .get(`/rest_api/trade_name_library/${id}`, getConfig())
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch({
                    type: GET_TNL_SUCCESS,
                    data: response,
                });

                return response
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: getTNL(id)
                })
            });
    };
}

export function copyTNL(id) {
    return (dispatch) => {
        return axiosInstance
            .post(`/rest_api/trade_name_library/${id}/copy`, {}, getConfig(),)
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: TNL_COPY_SUCCESS,
                    data: response,
                });

                return { type: TNL_COPY_SUCCESS, response};
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: copyTNL(id)
                })
            });
    };
}

export function recopyTNL(data) {
    return (dispatch) => {
        const compoundIngredients = data.compoundIngredientIds?.length
            ? `?compound_ingredient_ids=${data.compoundIngredientIds.join('&compound_ingredient_ids=')}`
            : '';

        return axiosInstance
            .patch(`/rest_api/trade_name_library/${data.id}/recopy${compoundIngredients}`, {}, getConfig(),)
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: TNL_RECOPY_SUCCESS,
                    data: response,
                });

                return TNL_RECOPY_SUCCESS
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: recopyTNL(data)
                })
            });
    };
}

export function requestRM(data) {
    return (dispatch) => {
        return axiosInstance
            .post('/rest_api/trade_name_library/request_rm', data, getConfig(),)
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: REQUEST_RM_SUCCESS,
                    data: response,
                });

                return REQUEST_RM_SUCCESS
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: requestRM(data)
                })
            });
    };
}
