export const SET_RM_EDITMODE = 'SET_RM_EDITMODE';
export const SET_SHOW_EDIT_CONFIRM_MODAL = 'SET_SHOW_EDIT_CONFIRM_MODAL';
export const SET_FORMULA_EDITMODE = 'SET_FORMULA_EDITMODE';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_SUB_CATEGORIES = 'GET_PRODUCT_SUB_CATEGORIES';
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_PRODUCT_BRANDS = 'GET_PRODUCT_BRANDS';
export const GET_IN_MARKET_BRANDS = 'GET_IN_MARKET_BRANDS';
export const GET_SHARED_PRODUCT_BRANDS = 'GET_SHARED_PRODUCT_BRANDS';
export const GET_PRODUCT_SIZES = 'GET_PRODUCT_SIZES';
export const GET_PRODUCT_RESTRICTION_TAGS = 'GET_PRODUCT_RESTRICTION_TAGS';
export const GET_TABLE_LAYOUT = 'GET_TABLE_LAYOUT';
export const UPDATE_TABLE_LAYOUT = 'UPDATE_TABLE_LAYOUT';
export const UPDATE_PRODUCT_RESTRICTION_TAGS = 'UPDATE_PRODUCT_RESTRICTION_TAGS';
export const CLEAR_PRODUCT_SIZES = 'CLEAR_PRODUCT_SIZES';
export const UPDATE_PRODUCT_SIZES = 'UPDATE_PRODUCT_SIZES';
export const CREATE_PRODUCT_SIZES = 'CREATE_PRODUCT_SIZES';
export const DELETE_PRODUCT_SIZES = 'DELETE_PRODUCT_SIZES';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT_DBS = 'GET_PRODUCT_DBS';
export const CREATE_PRODUCT_DBS = 'CREATE_PRODUCT_DBS';
export const UPDATE_PRODUCT_DBS = 'UPDATE_PRODUCT_DBS';
export const CLEAR_PRODUCT_DBS = 'CLEAR_PRODUCT_DBS';
export const CREATE_NEW_FOLDER = 'CREATE_NEW_FOLDER';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const GET_EDITED_USER = 'GET_EDITED_USER';
export const GET_EDITED_USER_PERMISSIONS = 'GET_EDITED_USER_PERMISSIONS';
export const SET_SHOW_MAINTENANCE_MESSAGE = 'SET_SHOW_MAINTENANCE_MESSAGE';
export const GET_MAINTENANCE_MESSAGES = 'GET_MAINTENANCE_MESSAGES';
export const GET_COMPANY_PERMISSIONS  = 'GET_COMPANY_PERMISSIONS';
export const GET_ALL_PERMISSIONS  = 'GET_ALL_PERMISSIONS';
export const GET_ALL_PERMISSIONS_STARTED = 'GET_ALL_PERMISSIONS_STARTED';
export const GET_COMPANY_GRAND_CATEGORIES  = 'GET_COMPANY_GRAND_CATEGORIES';
export const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE';
export const GET_COMPANY_TYPES = 'GET_COMPANY_TYPES';
export const GET_COMPANY_BRANDS = 'GET_COMPANY_BRANDS';
export const GET_COMPANY_FORMULA_BRANDS = 'GET_COMPANY_FORMULA_BRANDS';
export const GET_COMPANY_FORMULA_STATUSES = 'GET_COMPANY_FORMULA_STATUSES';
export const GET_COMPANY_PROFILE_USERS = 'GET_COMPANY_PROFILE_USERS';
export const LOAD_COMPANY_PROFILE_USERS = 'LOAD_COMPANY_PROFILE_USERS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_COMPANY = 'UPDATE_USER_COMPANY';
export const GET_USER_COMPANY_CURRENCY = 'GET_USER_COMPANY_CURRENCY';
export const GET_REGULATORY = 'GET_REGULATORY';
export const GET_ACTIVES = 'GET_ACTIVES';
export const GET_FOLDERS = 'GET_FOLDERS';
export const CREATE_PRODUCTS = 'CREATE_PRODUCTS';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_PRODUCTS_STATUS = 'UPDATE_PRODUCTS_STATUS';
export const UPDATE_PRODUCT_GROUPS = 'UPDATE_PRODUCT_GROUPS';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const CLOSE_ERROR = 'CLOSE_ERROR';
export const GET_TOXINS = 'GET_TOXINS';
export const GET_FORMULATOR_PRODUCT = 'GET_FORMULATOR_PRODUCT';
export const CLEAR_FORMULATOR_PRODUCT = 'CLEAR_FORMULATOR_PRODUCT';
export const CLEAR_FORMULATOR_FORMULA = 'CLEAR_FORMULATOR_FORMULA';
export const GET_FORMULATOR_FORMULA_PARAMS = 'GET_FORMULATOR_FORMULA_PARAMS';
export const UPDATE_FORMULATOR_FORMULA_NF_PARAMS = 'UPDATE_FORMULATOR_FORMULA_NF_PARAMS';
export const GET_FORMULATOR_FORMULA_TOTAL_CONCENTRATION = 'GET_FORMULATOR_FORMULA_TOTAL_CONCENTRATION';
export const GET_FORMULATOR_FORMULA_TOTAL_COST = 'GET_FORMULATOR_FORMULA_TOTAL_COST';
export const GET_FORMULATOR_FORMULA_TOTAL_NATURALITY = 'GET_FORMULATOR_FORMULA_TOTAL_NATURALITY';
export const GET_FORMULATOR_FORMULA_COMPOUND_PARAMS = 'GET_FORMULATOR_FORMULA_COMPOUND_PARAMS';
export const GET_FORMULA_COMPOUND_PARAM_INGREDIENTS = 'GET_FORMULA_COMPOUND_PARAM_INGREDIENTS';
export const GET_FORMULA_FILES = 'GET_FORMULA_FILES';
export const UPLOAD_FORMULA_FILE = 'UPLOAD_FORMULA_FILE';
export const UPDATE_FORMULA_FILE = 'UPDATE_FORMULA_FILE';
export const DELETE_FORMULA_FILE = 'DELETE_FORMULA_FILE';
export const UPDATE_FORMULA_COMPOUND_CHILD_INGREDIENT = 'UPDATE_FORMULA_COMPOUND_CHILD_INGREDIENT';
export const UPDATE_FORMULA_INGREDIENT = 'UPDATE_FORMULA_INGREDIENT';
export const UPDATE_FORMULA_INGREDIENT_COMPOUND = 'UPDATE_FORMULA_INGREDIENT_COMPOUND';
export const ADD_FORMULA_INGREDIENT_COMPOUND = 'ADD_FORMULA_INGREDIENT_COMPOUND';
export const DELETE_FORMULA_INGREDIENT_COMPOUND = 'DELETE_FORMULA_INGREDIENT_COMPOUND';
export const GET_TOXIN_CATEGORIES = 'GET_TOXIN_CATEGORIES';
export const UPDATE_FOLDER_BULK = 'UPDATE_FOLDER_BULK';
export const DELETE_FOLDERS_BULK = 'DELETE_FOLDERS_BULK';
export const UPDATE_PRODUCT_INGREDIENTS = 'UPDATE_PRODUCT_INGREDIENTS';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const GET_PRODUCT_BENEFITS = 'GET_PRODUCT_BENEFITS';
export const GET_INGREDIENTS = 'GET_INGREDIENTS';
export const REQUEST_REVIEW_INGREDIENT = 'REQUEST_REVIEW_INGREDIENT';
export const REQUEST_REVIEW_SUPPLIER = 'REQUEST_REVIEW_SUPPLIER';
export const REQUEST_REVIEW_MANUFACTURERS = 'REQUEST_REVIEW_MANUFACTURERS';
export const REQUEST_REVIEW_RM = 'REQUEST_REVIEW_RM';
export const UPDATE_REQUEST_REVIEW_INGREDIENT = 'UPDATE_REQUEST_REVIEW_RM';
export const GET_BLACK_LIST = 'GET_BLACK_LIST';
export const GET_PARTNERSHIPS = 'GET_PARTNERSHIPS';
export const GET_PARTNERSHIPS_SHARED_ITEMS = 'GET_PARTNERSHIPS_SHARED_ITEMS';
export const GET_COMPOUND_INGREDIENTS = 'GET_COMPOUND_INGREDIENTS';
export const GET_INGREDIENTS_FOR_LIBRARY = 'GET_INGREDIENTS_FOR_LIBRARY';
export const GET_INGREDIENT = 'GET_INGREDIENT';
export const COPY_INGREDIENT = 'COPY_INGREDIENT';
export const REMOVE_PARNTERSHIP = 'REMOVE_PARNTERSHIP';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_BLACK_LIST_ITEMS = 'GET_BLACK_LIST_ITEMS';
export const REMOVE_BLACKLIST_ITEM = 'REMOVE_BLACKLIST_ITEM';
export const FORBIDDEN_BLACKLIST = 'FORBIDDEN_BLACKLIST';
export const GET_COMPOUND_INGREDIENT = 'GET_COMPOUND_INGREDIENT';
export const FIELD_ERRORS = 'FIELD_ERRORS';
export const GET_PHASES = 'GET_PHASES';
export const CREATE_PHASE = 'CREATE_PHASE';
export const GET_REPORTS = 'GET_REPORTS';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const REMOVE_REPORT = 'REMOVE_REPORT';
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT';
export const DELETE_RM = 'DELETE_RM';
export const UPDATE_INGREDIENT_TAG = 'UPDATE_INGREDIENT_TAG';
export const GET_FUNCTIONS_LIST = 'GET_FUNCTIONS_LIST';
export const GET_SHARED_PRODUCT = 'GET_SHARED_PRODUCT';
export const REMOVE_COMPOUND_INGREDIENT = 'REMOVE_COMPOUND_INGREDIENT';
export const GET_CERTIFICATIONS = 'GET_CERTIFICATIONS';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_MANUFACTURERS = 'GET_MANUFACTURERS';
export const GET_MAX_PRICE = 'GET_MAX_PRICE';
export const UPDATE_COMPOUND_INGREDIENT = 'UPDATE_COMPOUND_INGREDIENT';
export const UPDATE_COMPOUND_INGREDIENT_STATUS = 'UPDATE_COMPOUND_INGREDIENT_STATUS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_REGIONS = 'GET_REGIONS';
export const CREATE_COMPOUND_INGREDIENT = 'CREATE_COMPOUND_INGREDIENT';
export const DUPLICATE_COMPOUND_INGREDIENT = 'DUPLICATE_COMPOUND_INGREDIENT';
export const GET_SOURCES = 'GET_SOURCES';
export const GET_ORIGINS = 'GET_ORIGINS';
export const UPDATE_PERCENTAGE = 'UPDATE_PERCENTAGE';
export const GET_RESTRICTION_ANALYZE = 'GET_RESTRICTION_ANALYZE';
export const CLEAR_RESTRICTIONS = 'CLEAR_RESTRICTIONS';
export const CLEAR_PARTNERSHIPS_RESTRICTIONS = 'CLEAR_PARTNERSHIPS_RESTRICTIONS'
export const CLEARE_PRODUCT = 'CLEARE_PRODUCT';
export const GENERATE_PRODUCTS_REPORTS = 'GENERATE_PRODUCTS_REPORTS';
export const UPDATE_BLACK_LIST_ITEM = 'UPDATE_BLACK_LIST_ITEM';
export const SHARE_FORMULA_PRODUCTS_BULK_WITH_PARTNERS =
    'SHARE_FORMULA_PRODUCTS_BULK_WITH_PARTNERS';
export const DELETE_SHARED_FORMULA_PRODUCTS_BULK =
    'DELETE_SHARED_FORMULA_PRODUCTS_BULK';
export const GET_FILE_TYPES = 'GET_FILE_TYPES';
export const GET_FILE_TAGS = 'GET_FILE_TAGS';
export const GET_CUSTOM_TAGS = 'GET_CUSTOM_TAGS';
export const GET_TAGS = 'GET_TAGS';
export const UPLOAD_FILE_COMPOUND = 'UPLOAD_FILE_COMPOUND';
export const DUPLICATE_PRODUCT = 'DUPLICATE_PRODUCT';
export const DELETE_RM_FILE_SUCCESS = 'DELETE_RM_FILE_SUCCESS';
export const REFRESH_SHARED_PRODUCT = 'REFRESH_SHARED_PRODUCT';
export const GET_PRODUCT_NOTES = 'GET_PRODUCT_NOTES';
export const CREATE_PRODUCT_NOTE = 'CREATE_PRODUCT_NOTE';
export const UPDATE_PRODUCT_NOTE = 'UPDATE_PRODUCT_NOTE';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS';
export const GET_COMPOUND_INGREDIENT_PARAM = 'GET_COMPOUND_INGREDIENT_PARAM';
export const GET_COMPOUND_INGREDIENT_IMPUTITY = 'GET_COMPOUND_INGREDIENT_IMPUTITY';
export const GET_COMPOUND_INGREDIENT_ASSOCIATED_INCI = 'GET_COMPOUND_INGREDIENT_ASSOCIATED_INCI';
export const GET_COMPOUND_INGREDIENT_ALLERGEN = 'GET_COMPOUND_INGREDIENT_ALLERGEN';
export const GET_COMPOUND_INGREDIENT_INCIDENTAL = 'GET_COMPOUND_INGREDIENT_INCIDENTAL';
export const UPDATE_COMPOUND_INGREDIENT_PARAM_LIST = 'UPDATE_COMPOUND_INGREDIENT_PARAM_LIST';
export const UPDATE_COMPOUND_INGREDIENT_IMPURITY_LIST = 'UPDATE_COMPOUND_INGREDIENT_IMPURITY_LIST';
export const UPDATE_COMPOUND_INGREDIENT_ALLERGEN_LIST = 'UPDATE_COMPOUND_INGREDIENT_ALLERGEN_LIST';
export const UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_LIST = 'UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_LIST';
export const CLEAR_PARAMS = 'CLEAR_PARAMS';
export const UPDATE_COMPOUND_INGREDIENT_IMPURIT_FIELD = 'UPDATE_COMPOUND_INGREDIENT_IMPURIT_FIELD';
export const UPDATE_COMPOUND_INGREDIENT_PARAM_FIELD = 'UPDATE_COMPOUND_INGREDIENT_PARAM_FIELD';
export const UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_FIELD = 'UPDATE_COMPOUND_INGREDIENT_INCIDENTAL_FIELD';
export const UPDATE_COMPOUND_INGREDIENT_ALLERGEN_FIELD = 'UPDATE_COMPOUND_INGREDIENT_ALLERGEN_FIELD';
export const SET_UPLOADED_DOCS = 'SET_UPLOADED_DOCS';
export const LOG_REGULATORY_LINK_SUCCESS = 'LOG_REGULATORY_LINK_SUCCESS';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const TNL_PUBLISH_SUCCESS = 'TNL_PUBLISH_SUCCESS';
export const TNL_RECOPY_SUCCESS = 'TNL_RECOPY_SUCCESS';
export const TNL_COPY_SUCCESS = 'TNL_COPY_SUCCESS';
export const GET_TNL_SUCCESS = 'GET_TNL_SUCCESS';
export const GET_TNL_LIST_SUCCESS = 'GET_TNL_LIST_SUCCESS';
export const TNL_REPUBLISH_SUCCESS = 'TNL_REPUBLISH_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const REQUEST_DOCUMENTS_SUCCESS = 'REQUEST_DOCUMENTS_SUCCESS';
export const REQUEST_SYNC_CENTRIC_SUCCESS = 'REQUEST_SYNC_CENTRIC_SUCCESS';
export const REQUEST_RM_SUCCESS = 'REQUEST_RM_SUCCESS';
export const CARA_REQUEST_SUCCESS = 'CARA_REQUEST_SUCCESS';
export const GET_PDF_REPORTS = 'GET_PDF_REPORTS';
export const CARA_RM_REQUEST_SUCCESS = 'CARA_RM_REQUEST_SUCCESS';
export const CARA_RM_LIST_REQUEST_SUCCESS = 'CARA_RM_LIST_REQUEST_SUCCESS';
export const CARA_TNL_LIST_REQUEST_SUCCESS = 'CARA_TNL_LIST_REQUEST_SUCCESS';
export const CLEAR_RM_LIST = 'CLEAR_RM_LIST';
export const CLEAR_TNL_LIST = 'CLEAR_TNL_LIST';
export const CARA_FORMULA_REQUEST_SUCCESS = 'CARA_FORMULA_REQUEST_SUCCESS';
export const GET_TRASH_COMPOUND_INGREDIENTS = 'GET_TRASH_COMPOUND_INGREDIENTS';
export const UPDATE_TRASH_COMPOUND_INGREDIENTS = 'UPDATE_TRASH_COMPOUND_INGREDIENTS';
export const DELETE_TRASH_COMPOUND_INGREDIENTS = 'DELETE_TRASH_COMPOUND_INGREDIENTS'
export const GET_TRASH_FORMULATOR_FOLDERS = 'GET_TRASH_FORMULATOR_FOLDERS';
export const UPDATE_TRASH_FORMULATOR_FOLDERS = 'UPDATE_TRASH_FORMULATOR_FOLDERS';
export const DELETE_TRASH_FORMULATOR_FOLDERS = 'DELETE_TRASH_FORMULATOR_FOLDERS';
export const GET_TRASH_FORMULATOR_PRODUCTS = 'GET_TRASH_FORMULATOR_PRODUCTS';
export const UPDATE_TRASH_FORMULATOR_PRODUCTS = 'UPDATE_TRASH_FORMULATOR_PRODUCTS';
export const DELETE_TRASH_FORMULATOR_PRODUCTS = 'DELETE_TRASH_FORMULATOR_PRODUCTS';
export const GET_TRASH_CONSUMER_PRODUCTS = 'GET_TRASH_CONSUMER_PRODUCTS';
export const UPDATE_TRASH_CONSUMER_PRODUCTS = 'UPDATE_TRASH_CONSUMER_PRODUCTS';
export const DELETE_TRASH_CONSUMER_PRODUCTS = 'DELETE_TRASH_CONSUMER_PRODUCTS';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const CARA_CREATE_TABLE_REQUEST_SUCCESS = 'CARA_CREATE_TABLE_REQUEST_SUCCESS';
export const CARA_SENTIMENT_WORD_CLOUD_REQUEST_SUCCESS = 'CARA_SENTIMENT_WORD_CLOUD_REQUEST_SUCCESS';
export const CARA_RM_LIST_FILTER_REQUEST_SUCCESS = 'CARA_RM_LIST_FILTER_REQUEST_SUCCESS';
export const CARA_TNL_LIST_FILTER_REQUEST_SUCCESS = 'CARA_TNL_LIST_FILTER_REQUEST_SUCCESS';
export const CLEAR_RM_LIST_FILTER = 'CLEAR_RM_LIST_FILTER';
export const CLEAR_TNL_LIST_FILTER = 'CLEAR_TNL_LIST_FILTER';
export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';
export const LOCK_COMPOUND_INGREDIENT = 'LOCK_COMPOUND_INGREDIENT';
export const GET_FORMULA_SPECIFICATIONS = 'GET_FORMULA_SPECIFICATIONS';
export const GET_SPECIFICATION_METHODS = 'GET_SPECIFICATION_METHODS';
export const GET_SPECIFICATION_METHODS_SEARCH = 'GET_SPECIFICATION_METHODS_SEARCH';
export const UPDATE_SPECIFICATIONS_LIST = 'UPDATE_SPECIFICATIONS_LIST';
export const DELETE_CUSTOM_METHOD_SEARCH = 'DELETE_CUSTOM_METHOD_SEARCH';
export const CLEAR_CLEAR_PAGE = 'CLEAR_CLEAR_PAGE';
export const GET_SCREENING_NOTIFICATIONS = 'GET_SCREENING_NOTIFICATIONS';
export const CARA_DOPPELGANGERS_REQUEST_SUCCESS = 'CARA_DOPPELGANGERS_REQUEST_SUCCESS';
export const STATUSES_SETTINGS_REQUEST_SUCCESS = 'STATUSES_SETTINGS_REQUEST_SUCCESS';
export const STATUSES_LIST_REQUEST_SUCCESS = 'STATUSES_LIST_REQUEST_SUCCESS';
export const CREATE_STATUSES_LIST_REQUEST_SUCCESS = 'CREATE_STATUSES_LIST_REQUEST_SUCCESS';
export const UPDATE_STATUSES_LIST_REQUEST_SUCCESS = 'UPDATE_STATUSES_LIST_REQUEST_SUCCESS';
export const DELETE_STATUSES_LIST_REQUEST_SUCCESS = 'DELETE_STATUSES_LIST_REQUEST_SUCCESS';
export const RESORT_STATUSES_LIST_REQUEST_SUCCESS = 'RESORT_STATUSES_LIST_REQUEST_SUCCESS';
export const STATUSES_USERS_LIST_REQUEST_SUCCESS = 'STATUSES_USERS_LIST_REQUEST_SUCCESS';
export const STATUSES_INITIATED_LIST_REQUEST_SUCCESS = 'STATUSES_INITIATED_LIST_REQUEST_SUCCESS';
export const GET_RAW_MATERIAL = 'GET_RAW_MATERIAL';
export const GET_RAW_MATERIAL_FILES = 'GET_RAW_MATERIAL_FILES';
export const GET_ALL_RAW_MATERIALS = "GET_ALL_RAW_MATERIALS";
export const UPDATE_RAW_MATERIALS = 'UPDATE_RAW_MATERIALS';
export const REMOVE_RAW_MATERIALS = 'REMOVE_RAW_MATERIALS';
export const CREATE_RAW_MATERIALS = 'CREATE_RAW_MATERIALS';
export const LOCK_RAW_MATERIAL = 'LOCK_COMPOUND_INGREDIENT';
export const REQUEST_REVIEW_RAW_MATERIALS = 'REQUEST_REVIEW_RAW_MATERIALS';
export const UPDATE_RAW_MATERIAL_STATUS = 'UPDATE_RAW_MATERIAL_STATUS';
export const DUPLICATE_RAW_MATERIALS = 'DUPLICATE_RAW_MATERIALS';
export const RESORT_FORMULA_PARAMS = 'RESORT_FORMULA_PARAMS';
export const FORMULATOR_FORMULA_RESORT = 'FORMULATOR_FORMULA_RESORT';
export const TRACK_DOWNLOAD_SPL = 'TRACK_DOWNLOAD_SPL';
