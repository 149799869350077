import React from 'react';

import Loader from '../Loader'

import styles from './index.module.scss';

const Loading = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return <Loader fw />;
    }
    // Handle the error state
    else if (error) {
        window.location.reload();

        return   <div className={styles['problem']}>
            {/*<div className={styles['problem-icon']}>*/}

            {/*</div>*/}
            {/*<div className={styles['']}>*/}
            {/*    <div className={styles['problem__title']}>Sorry, there was a problem loading the page :(</div> Try to refresh the page or{' '}<br/>*/}
            {/*    <a*/}
            {/*        onClick={() => {*/}
            {/*            window.location.reload();*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        click here*/}
            {/*    </a>*/}
            {/*</div>*/}

        </div>;
    }
    else {
        return null;
    }
};

export default Loading;
