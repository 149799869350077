import React from 'react';
import {connect} from 'react-redux';

import {removeNotice} from '../../../actions';

import styles from './index.module.scss';

const NoticeLine = (props) => {

  const {notices} = props;

  if (notices.length === 0) {
    return null;
  }

  return (
    <div className={styles['holder']}>
      <div className={styles['holder__inner']}>
        {notices.map(({key, message}) =>
          <div className={styles['msg']}>
            {message}
            <div
              className={styles['msg__close']}
              onClick={() => props.removeNotice(key)}
            />
          </div>
        )}
      </div>
    </div>
  );

};

export default connect(
  state => ({
    notices: state.general.notices
  }),
  dispatch => ({
    removeNotice: (key) => dispatch(removeNotice(key))
  })
)(NoticeLine);

