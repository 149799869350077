import React from 'react';
import classNames from 'classnames';
import VendorDrawer from 'rc-drawer';

import Logo from "../Logo";

import 'rc-drawer/assets/index.css';

import styles from './index.module.scss';

const Drawer = (props) => {

  const {className, onClose, children, ...rest} = props;

  return (

    <VendorDrawer
      width={285}
      handler={false}
      level={null}
      placement="right"
      className={classNames('page-theme-v2', className)}
      {...rest}
    >
      <div className={styles['drawer']}>

        <div className={styles['drawer__header']}>
          <Logo className={styles['drawer__logo']}/>
          <div onClick={onClose} className={styles['drawer__close-icon']}/>
        </div>

        <div className={styles['drawer__content']}>
          {children}
        </div>

      </div>

    </VendorDrawer>
  );

};

export default Drawer;
