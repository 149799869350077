/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from 'config';
import { ReactComponent as EvoNexusLogo } from 'assets/img/EvoNexusLogo.svg';

import { openPressPopup } from '../../actions';
import store from '../../store';
import { Section, Container, Columns } from '../Layout';
import FollowUs from '../FollowUs';

import styles from './index.module.scss';
import { segmentsFooterTrack } from '../../utils/trackEvents';
import { URLS_DETECT } from './consts';

const Footer = ({
  user,
  formulator,
  location,
  formulatorUser,
}) => {
  const faUser = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
  const showFAFooter =
      window.location.pathname.includes('/fa') &&
      !window.location.pathname.includes('faq') &&
      !window.location.pathname.includes('face') &&
      faUser && faUser.access;
  const isNewFooter = true; // Temporary

  return showFAFooter ? null : (
    <Section
      renderAs="footer"
      className={`${styles['wrapper']} ${config.analytic.classes.footer}`}
    >
      <Container>
        <div className={`${styles['section']} ${styles['top-section']}`}>
          <Columns multiline={false}>
            <Columns.Column
              className={styles['connect-column']}
              tablet={{ size: 6 }}
              desktop={{ size: 4 }}
            >
              <div
                className={`${styles['heading']} ${styles['connect-heading']}`}
              >
                Connect & Get the Latest!
              </div>

              {/*<SubscriptionForm className={styles['subscription']} />*/}
              {/*{!isNewFooter && (*/}
              {/*  <Link to="/email-signup" className={styles.link}>*/}
              {/*    Why join our list?*/}
              {/*  </Link>*/}
              {/*)}*/}
              <FollowUs
                  location={location}
                  formulatorUser={formulatorUser}
                className={styles['follow-us']}
                isNewFooter={true}
              />
            </Columns.Column>

            <Columns.Column
              className={styles['menus-column']}
              tablet={{ size: 6 }}
              desktop={{ size: 8 }}
            >
              <Columns>
                <Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>
                  <div className={styles['heading']}>The Good Face Project</div>

                  <ul className={styles['menu']}>
                    <li>
                      <Link
                          to="/about"
                          // onClick={() => {
                          //   if (URLS_DETECT[location.pathname]) {
                          //     segmentsFooterTrack({
                          //       user: formulatorUser,
                          //       name: 'About Button Clicked',
                          //       description:
                          //           'User clicks on About button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                          //       track_fields: {
                          //         $current_url: window.location.href,
                          //         current_page: URLS_DETECT[location.pathname],
                          //       },
                          //     });
                          //   }
                          // }}
                      >About</Link>
                    </li>
                    <li>
                      <Link
                          to="/faq"
                          // onClick={() => {
                          //   if (URLS_DETECT[location.pathname]) {
                          //     segmentsFooterTrack({
                          //       user: formulatorUser,
                          //       name: 'FAQ Button Clicked',
                          //       description:
                          //           'User clicks on FAQ button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                          //       track_fields: {
                          //         $current_url: window.location.href,
                          //         current_page: URLS_DETECT[location.pathname],
                          //       },
                          //     });
                          //   }
                          // }}
                      >{isNewFooter ? 'FAQ' : 'FAQs'}</Link>
                    </li>
                    {!isNewFooter && (
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/demo"
                        target="_blank"
                        onClick={(e) => {
                          // store.dispatch(openPressPopup());

                          if (URLS_DETECT[location.pathname]) {
                            segmentsFooterTrack({
                              user: formulatorUser,
                              name: 'Contact Button Clicked',
                              description:
                                  'User clicks on Contact button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page',
                              track_fields: {
                                $current_url: window.location.href,
                                current_page: URLS_DETECT[location.pathname],
                              },
                            });
                          }
                        }}
                      >
                        {isNewFooter ? 'Contact' : 'Press Inquiries'}
                      </Link>
                    </li>
                    {/*<li><a href="#" onClick={e => {*/}
                    {/*  e.preventDefault();*/}
                    {/*  store.dispatch(openRequestReviewPopup());*/}
                    {/*}}>Request Product Review</a></li>*/}
                  </ul>
                </Columns.Column>

                {/*<Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>*/}
                {/*  <div className={styles['heading']}>Good Face App</div>*/}

                {/*  <ul className={`${styles['menu']} ${styles['app-menu']}`}>*/}
                {/*    {!isNewFooter && (*/}
                {/*      <li>*/}
                {/*        <Link to="/app">Learn More</Link>*/}
                {/*      </li>*/}
                {/*    )}*/}
                {/*    <li>*/}
                {/*      <DownloadAppButton*/}
                {/*        onClick={() => {*/}
                {/*          if (URLS_DETECT[location.pathname]) {*/}
                {/*            segmentsFooterTrack({*/}
                {/*              user: formulatorUser,*/}
                {/*              name: 'Download on the App Store Button Clicked',*/}
                {/*              description:*/}
                {/*                'User clicks on Download on the App Store button in any of the following pages: About, Careers, Compare, Consumer FAQ, Consumer Blog, Research + Shop, FAQ, Formulators & Manufacturers, Brands, Retailers, Ingredient Suppliers, Claim Validation, Home page, Login Page ',*/}
                {/*              track_fields: {*/}
                {/*                $current_url: window.location.href,*/}
                {/*                current_page: URLS_DETECT[location.pathname],*/}
                {/*              },*/}
                {/*            });*/}
                {/*          }*/}
                {/*        }}*/}
                {/*        link={config.iosAppLink_Footer}*/}
                {/*        className={styles['ios-app-btn']}*/}
                {/*        icon={false}*/}
                {/*      >*/}
                {/*        <IOSBtnIcon />*/}
                {/*      </DownloadAppButton>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</Columns.Column>*/}

                {!isNewFooter && (
                  <Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>
                    <div className={styles['heading']}>Retailers</div>

                    <ul className={styles['menu']}>
                      <li>
                        <Link to="/retail">API Solutions</Link>
                      </li>
                    </ul>
                  </Columns.Column>
                )}

                {!isNewFooter && (
                  <Columns.Column tablet={{ size: 12 }} desktop={{ size: 6 }}>
                    <div className={styles['heading']}>Brands</div>

                    <ul className={styles['menu']}>
                      <li>
                        <Link to="/brands">Good Face Index Membership</Link>
                      </li>
                      <li>
                        <Link to="/">Innovation Platform</Link>
                      </li>
                      {(!user.isLoggedIn || user.role === 'company_user') && (
                        <li>
                          {user.isLoggedIn ? (
                            <a href="/sa/products#/">Brand Login</a>
                          ) : (
                            <Link to="/accounts/login?type=brand&next=/sa/products#/">
                              Brand Login
                            </Link>
                          )}
                        </li>
                      )}
                      {(user.isLoggedIn || formulator.isLoggedIn) && (
                        <li>
                          <a href="/accounts/logout">Logout</a>
                        </li>
                      )}
                    </ul>
                  </Columns.Column>
                )}
              </Columns>
            </Columns.Column>
          </Columns>
        </div>

        {/*<div className={`${styles['section']} ${styles['blog-section']}`}>*/}
        {/*  <Link to="/face-it-mag" className={styles['heading']}>*/}
        {/*    Blog*/}
        {/*  </Link>*/}

        {/*  <Columns>*/}
        {/*    <Columns.Column tablet={{ size: 4 }}>*/}
        {/*      <ul className={`${styles['menu']} ${styles['blog-menu']}`}>*/}
        {/*        <li>*/}
        {/*          <Link to="/articles/what-is-clean-beauty">*/}
        {/*            What is Clean Beauty?*/}
        {/*          </Link>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*          <Link to="/articles/where_clean_beauty_is_headed_in_2021">*/}
        {/*            Top Clean Beauty Trends 2021*/}
        {/*          </Link>*/}
        {/*        </li>*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/check-ingredients-in-cosmetics">*/}
        {/*              Check Ingredients for Safety*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/best-cleanser-and-exfoliator-for-skin-type">*/}
        {/*              Guide to Cleansing and Exfoliating*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*        {isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/non-toxic-makeup">*/}
        {/*              Guide to Non-Toxic Makeup*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*      </ul>*/}
        {/*    </Columns.Column>*/}

        {/*    <Columns.Column tablet={{ size: 4 }}>*/}
        {/*      <ul className={`${styles['menu']} ${styles['blog-menu']}`}>*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/non-toxic-makeup">*/}
        {/*              Guide to Non-Toxic Makeup*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*        {isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/check-ingredients-in-cosmetics">*/}
        {/*              Check Ingredients for Safety*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/non-toxic-shampoo-conditioner">*/}
        {/*              Best Non-Toxic Haircare*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*        <li>*/}
        {/*          <Link to="/articles/best-clean-products-for-men">*/}
        {/*            Best Clean Skincare for Men*/}
        {/*          </Link>*/}
        {/*        </li>*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/skincare-routine-for-men">*/}
        {/*              The Ultimate Men’s Skincare Routine*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*      </ul>*/}
        {/*    </Columns.Column>*/}

        {/*    <Columns.Column tablet={{ size: 4 }}>*/}
        {/*      <ul className={`${styles['menu']} ${styles['blog-menu']}`}>*/}
        {/*        <li>*/}
        {/*          <Link to="/articles/best-non-toxic-moisturizers">*/}
        {/*            Best Non-Toxic Moisturizers*/}
        {/*          </Link>*/}
        {/*        </li>*/}
        {/*        <li>*/}
        {/*          <Link to="/articles/pore-clogging-comedogenic-ingredients">*/}
        {/*            Pore Clogging Ingredients*/}
        {/*          </Link>*/}
        {/*        </li>*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/preservatives">*/}
        {/*              Preservatives in Cosmetics*/}
        {/*            </Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*        {!isNewFooter && (*/}
        {/*          <li>*/}
        {/*            <Link to="/articles/peptides">Peptides in Skincare</Link>*/}
        {/*          </li>*/}
        {/*        )}*/}
        {/*      </ul>*/}
        {/*    </Columns.Column>*/}
        {/*  </Columns>*/}
        {/*</div>*/}

        <div className={`${styles['section']} ${styles['bottom-section']}`}>
          <a
            href="https://evonexus.org"
            target="_blank"
            className={styles['logo']}
            rel="noopener noreferrer"
          >
            <EvoNexusLogo alt="Evo Nexus Logo" />
          </a>

          <div className={styles['links-wrap']}>
            <div className={styles['links']}>
              <Link to="/f_privacy_policy">Privacy Policy</Link>
              <Link to="/f_terms_and_conditions">Terms & Conditions</Link>
            </div>

            <div className={styles['copyright']}>
              <span>The Good Face Project, Inc 2024.</span> &nbsp;
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default connect((state) => ({
  user: state.user,
  formulator: state.formulator,
  formulatorUser: state.formulator.user,
  previousLocation: state.formulator.previousLocation,
  featureFlags: state.user.featureFlags,
}))(withRouter(Footer));
