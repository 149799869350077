import axiosInstance from './axiosInstance';

import {
    STATUSES_SETTINGS_REQUEST_SUCCESS,
    STATUSES_LIST_REQUEST_SUCCESS, CREATE_STATUSES_LIST_REQUEST_SUCCESS,
    UPDATE_STATUSES_LIST_REQUEST_SUCCESS, DELETE_STATUSES_LIST_REQUEST_SUCCESS,
    RESORT_STATUSES_LIST_REQUEST_SUCCESS, STATUSES_USERS_LIST_REQUEST_SUCCESS,
    STATUSES_INITIATED_LIST_REQUEST_SUCCESS,
} from './types';
import { catchFunction, getConfig } from './utils';

export function getStatusesSettings() {
  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/statuses/settings`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: STATUSES_SETTINGS_REQUEST_SUCCESS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getStatusesSettings(),
        });
      });
  };
}

export function getStatusesList(data) {
  const countObjectsParam = data.count_related_objects ? '?count_related_objects=true' : '';

  return (dispatch) => {
    return axiosInstance
      .get(`/rest_api/companies/statuses/${data.type}${countObjectsParam}`, getConfig())
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch({
          type: STATUSES_LIST_REQUEST_SUCCESS,
          data: response,
        });

        return response;
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getStatusesList(data),
        });
      });
  };
}

export function getStatusesUsersList(data) {

    return (dispatch) => {
        return axiosInstance
            .get(`/rest_api/companies/statuses/${data.type}/users`, getConfig())
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch({
                    type: STATUSES_USERS_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: getStatusesUsersList(data),
                });
            });
    };
}

export function getStatusesInitiatedList(data) {

    return (dispatch) => {
        return axiosInstance
            .get(`/rest_api/companies/statuses/${data.type}/users_teams`, getConfig())
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch({
                    type: STATUSES_INITIATED_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: getStatusesInitiatedList(data),
                });
            });
    };
}

export function getStatusesNotifiedList(data) {

    return (dispatch) => {
        return axiosInstance
            .get(`/rest_api/companies/statuses/users_teams`, getConfig())
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch({
                    type: STATUSES_INITIATED_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: getStatusesNotifiedList(data),
                });
            });
    };
}

export function createStatusesList(data) {
    return (dispatch) => {
        return axiosInstance
            .post(`/rest_api/companies/statuses/${data.type}`, data.request, getConfig())
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: CREATE_STATUSES_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: createStatusesList(data)
                })
            });
    };
}

export function resortStatusesList(data) {
    return (dispatch) => {
        return axiosInstance
            .post(`/rest_api/companies/statuses/${data.type}/resort`, data, getConfig())
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: RESORT_STATUSES_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: resortStatusesList(data)
                })
            });
    };
}

export function updateStatusesList(data) {
    return (dispatch) => {
        return axiosInstance
            .patch(`/rest_api/companies/statuses/${data.type}/${data.status_id}`, data.request, getConfig())
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: UPDATE_STATUSES_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: updateStatusesList(data)
                })
            });
    };
}

export function deleteStatusesList(data) {
    return (dispatch) => {
        return axiosInstance
            .delete(`/rest_api/companies/statuses/${data.type}/${data.status_id}`, getConfig())
            .then((response) => { return response.data; })
            .then((response) => {
                dispatch({
                    type: DELETE_STATUSES_LIST_REQUEST_SUCCESS,
                    data: response,
                });

                return response;
            })
            .catch((error) => {
                catchFunction({
                    error,
                    dispatch,
                    refreshFunction: deleteStatusesList(data)
                })
            });
    };
}
