import axiosInstance from './axiosInstance';

import { GET_PDF_REPORTS } from "./types";
import { catchFunction, getConfig } from "./utils";

export function getPdfReports(productId, isNewApi = false) {
  const url = isNewApi
    ? `/rest_api/formulator/formulas/${productId}/pdf-report`
    : `/rest_api/formulator/products/${productId}/pdf_report`;

  return dispatch => {
    return axiosInstance.get(url, getConfig())
      .then(response => {
        return response.data;
      })
      .then(response => {
        dispatch({
          type: GET_PDF_REPORTS,
          data: response
        })

        return GET_PDF_REPORTS
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPdfReports(productId, isNewApi),
        });
      });
  }
}

export function getPdfSpecReport(data, productId) {

  return dispatch => {
    return axiosInstance.get(`/rest_api/formulator/formulas/${productId}/pdf-report?report_type=product_specs_pdf`, getConfig(data))
      .then(response => {
        return response.data;
      })
      .then(response => {
        dispatch({
          type: GET_PDF_REPORTS,
          data: response
        })

        return response
      })
      .catch((error) => {
        catchFunction({
          error,
          dispatch,
          refreshFunction: getPdfSpecReport(data, productId),
        });
      });
  }
}
