export const FLAGS_LIST = [
    'test',
    'product_qualities',
    'product_format_field',
    'price_last_update',
    'in_market_products',
    'consumer_db_retailer_api',
    'sso_login',
    'notifications',
    'resolved_warning_flags',
    'recopy_tnl',
    'display_maintenance_message',
    'my_formulas_new_api',
    'trash',
    'products_pagination',
    'user_permissions_editor',
    'compliance_rm',
    'centric_sync',
    'teams',
    'lock_rm',
    'account_settings_presets',
    'formula_status',
    'formula_editor_table_configuration',
    'cara_ingredient_name',
    'cara_ingredient_type',
    'settings_company_users',
    'company_details_new',
    'new_partners_api',
    'new_rm_filters',
    'new_formulas_filters',
    'select_all_formulas',
    'status_management',
    'cara_doppelgangers',
    'drag_n_drop_formulas',
    'rm_new_api',
    'folder_groups',
    'rm_confirmation_modal',
    'folder_groups_localhost',
    'formula_batch_card_report',
    'spl_report',
    'natural_content',
    'doppelgangers_detail',
    'frequency_of_use',
    'public_product_name',
    'qs',
]
